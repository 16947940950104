export function scaleFix() {
  var viewportmeta =
      document.querySelector && document.querySelector('meta[name="viewport"]'),
    ua = navigator.userAgent,
    gestureStart = function() {
      viewportmeta.content =
        'width=device-width, minimum-scale=0.25, maximum-scale=1.6';
    },
    scaleFix = function() {
      if (viewportmeta && /iPhone|iPad/.test(ua)) {
        /* && !/Opera Mini/.test(ua)*/
        var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
        if (v && parseInt(v[1]) < 6) {
          viewportmeta.content =
            'width=device-width, minimum-scale=1.0, maximum-scale=1.0';
          document.addEventListener('gesturestart', gestureStart, false);
        }
      }
    };
  scaleFix();
}
