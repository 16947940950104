/*####### cmp-buttons-current.js - v1.1 - 26.09.2017 #######*/
import { cmpButtons } from './cmp-buttons';
export const cmpButtonsCurrent = {
  /* extend cmpButtons */
  config: {
    object: cmpButtons || false,
    currentClassName: 'current',
  },
  init: function(config) {
    if (this.config.object.constructor === Object) {
      if (config && config.constructor === Object) {
        for (var i in config) {
          this.config[i] = config[i];
        }
      }
      this.addMethods();
    }
    return this;
  },
  addMethods: function() {
    var object = this.config.object;
    object.currentButton = this.currentButton;
  },
  currentButton: function(element, data) {
    var _this = cmpButtonsCurrent;
    if (this.groupName) {
      var $group = this.$getGroup(this.groupName),
        $element = [];
      if ($group.length) {
        if (element) {
          $element = $(element);
          if (data && data.multiGroupCurrent !== undefined) {
            var $parent = $element.parent();
            if ($parent.length) {
              $group = this.$getGroup(this.groupName, $parent);
            }
          }
        } else {
          $element = $group.filter('.' + _this.config.currentClassName).first();
        }
        if ($element.length) {
          $group.removeClass(_this.config.currentClassName);
          $element.addClass(_this.config.currentClassName);
        }
      }
    }
  },
};
