/*####### cmp-buttons.js - v1.1 - 26.09.2017 #######*/

export const cmpButtons = {
  config: {
    selector: 'body',
    buttons: {},
    error: false,
    ajaxGlobal: true,
  },
  init: function(config) {
    if (config && config.constructor === Object) {
      for (var i in config) {
        this.config[i] = config[i];
      }
    }
    this.listener();
    return this;
  },
  options: {},
  groupName: false,
  listener: function() {
    var _this = this;
    $(this.config.selector)
      .on('click', '[data-button]', function(event) {
        event.preventDefault();
        var $this = $(this),
          data = $this.data(),
          groupName = data.button || false;
        if ($this.is('.disabled') || $this.is('.wait')) {
          return;
        }
        if (!data.propagation) {
          event.stopPropagation();
        }
        if (groupName) {
          if (groupName != _this.groupName) {
            _this.groupName = groupName;
            _this.options = {};
            var $group = _this.$getGroup(groupName);
            if ($group.length) {
              $group.each(function() {
                _this.setOptions($(this).data());
              });
            }
          }
        } else {
          _this.groupName = false;
          _this.options = {};
          _this.setOptions(data);
        }
        if (data.extraParams) {
          if (data.extraParams.constructor === Object) {
            _this.options.extraParams = data.extraParams;
          } else {
            _this.options.extraParams = _this.extraParamsParse(
              data.extraParams
            );
          }
        } else if (_this.options.extraParams) {
          delete _this.options.extraParams;
        }
        if (data.value !== undefined) {
          _this.options.value = data.value;
        } else if (_this.options.value) {
          delete _this.options.value;
        }
        _this.click(data, this);
        if (_this.options.url) {
          _this.request(data, this);
          
        } else {
          _this.success(data, this);
        }
      })
      .on('focus', '[data-button]', function(event) {
        var data = $(this).data();
        _this.focus(data, this);
      })
      .on('blur', '[data-button]', function(event) {
        var data = $(this).data();
        _this.blur(data, this);
      });
  },
  extraParamsParse: function(params) {
    if (params) {
      if (params.indexOf('{') < 0) {
        params = '{' + params + '}';
      }
      return eval('(' + params + ')');
    }
    return false;
  },
  request: function(eldata, el) {
    var _this = this,
      value = this.options.value !== undefined ? this.options.value : false,
      data = this.options,
      params = {},
      $el = $(el);
    if (data.extraParams && data.extraParams.constructor === Object) {
      params = data.extraParams;
    }
    if (value) {
      if (data.name) {
        params[data.name] = value;
      } else {
        params['value'] = value;
      }
    }
    var ajaxOptions = {
      dataType: 'json',
      url: data.url,
      cache: data.cache || true,
      data: params,
      type: data.method || 'POST',
      global: _this.config.ajaxGlobal,
      beforeSend: function(jqXHR, settings) {
        $el.addClass('wait');
        var r = _this.beforeSend.call(_this, eldata, el, jqXHR, settings);
        if (r === false) {
          $el.removeClass('wait');
        }
        return r;
      },
      success: function(response, textStatus, jqXHR) {
        $el.removeClass('wait');
        if (response && response.constructor === Object) {
          _this.success.call(
            _this,
            eldata,
            el,
            response.status,
            response,
            textStatus,
            jqXHR
          );
        } else {
          _this.error.call(_this, eldata, el, response, textStatus, jqXHR);
        }
      },
      error: function(jqXHR, textStatus) {
        $el.removeClass('wait');
        _this.error.call(_this, eldata, el, {}, textStatus, jqXHR);
      },
    };
    $.ajax(ajaxOptions);
  },
  error: function(data, el, response, textStatus, jqXHR) {
    if (this.config.error && typeof this.config.error == 'function') {
      this.config.error.call(this, data, el, response, textStatus, jqXHR);
    }
  },
  click: function(data, el) {
    if (
      this.config.buttons.constructor === Object &&
      'id' in this.options &&
      this.options.id in this.config.buttons &&
      this.config.buttons[this.options.id].constructor === Object &&
      'click' in this.config.buttons[this.options.id] &&
      typeof this.config.buttons[this.options.id].click == 'function'
    ) {
      return this.config.buttons[this.options.id].click.call(this, data, el);
    }
  },
  focus: function(data, el) {
    if (
      this.config.buttons.constructor === Object &&
      'id' in this.options &&
      this.options.id in this.config.buttons &&
      this.config.buttons[this.options.id].constructor === Object &&
      'focus' in this.config.buttons[this.options.id] &&
      typeof this.config.buttons[this.options.id].focus == 'function'
    ) {
      return this.config.buttons[this.options.id].focus.call(this, data, el);
    }
  },
  blur: function(data, el) {
    if (
      this.config.buttons.constructor === Object &&
      'id' in this.options &&
      this.options.id in this.config.buttons &&
      this.config.buttons[this.options.id].constructor === Object &&
      'blur' in this.config.buttons[this.options.id] &&
      typeof this.config.buttons[this.options.id].blur == 'function'
    ) {
      return this.config.buttons[this.options.id].blur.call(this, data, el);
    }
  },
  beforeSend: function(data, el, jqXHR, settings) {
    if (
      this.config.buttons.constructor === Object &&
      'id' in this.options &&
      this.options.id in this.config.buttons &&
      this.config.buttons[this.options.id].constructor === Object &&
      'beforeSend' in this.config.buttons[this.options.id] &&
      typeof this.config.buttons[this.options.id].beforeSend == 'function'
    ) {
      return this.config.buttons[this.options.id].beforeSend.call(
        this,
        data,
        el,
        jqXHR,
        settings
      );
    }
  },
  success: function(data, el, status, response, textStatus, jqXHR) {
    if (
      this.config.buttons.constructor === Object &&
      'id' in this.options &&
      this.options.id in this.config.buttons
    ) {
      if (typeof this.config.buttons[this.options.id] == 'function') {
        this.config.buttons[this.options.id].call(
          this,
          data,
          el,
          response,
          textStatus,
          jqXHR
        ); //goToUrl function call
      } else if (this.config.buttons[this.options.id].constructor === Object) {
        if (status) {
          if (
            'statusTrue' in this.config.buttons[this.options.id] &&
            typeof this.config.buttons[this.options.id].statusTrue == 'function'
          ) {
            this.config.buttons[this.options.id].statusTrue.call(
              this,
              data,
              el,
              response,
              textStatus,
              jqXHR
            );
          }
        } else {
          if (
            'statusFalse' in this.config.buttons[this.options.id] &&
            typeof this.config.buttons[this.options.id].statusFalse ==
              'function'
          ) {
            this.config.buttons[this.options.id].statusFalse.call(
              this,
              data,
              el,
              response,
              textStatus,
              jqXHR
            );
          }
        }
      }
    }
  },
  setOptions: function(data) {
    for (var i in data) {
      if (i in this.options || i == 'value' || i == 'extraParams') {
        continue;
      }
      this.options[i] = data[i];
    }
  },
  $getGroup: function(groupName, root) {
    return $('[data-button="' + groupName + '"]', root || document.body);
  },
};
