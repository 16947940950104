/*####### cmp-buttons-toggle.js - v1.1 - 26.09.2017 #######*/
import { cmpButtons } from './cmp-buttons';
export const cmpButtonsToggle = {
  /* extend cmpButtons */
  config: {
    object: cmpButtons || false,
    disabledClassName: 'disabled',
    activeClassName: 'active',
  },
  init: function(config) {
    if (this.config.object.constructor === Object) {
      if (config && config.constructor === Object) {
        for (var i in config) {
          this.config[i] = config[i];
        }
      }
      this.addMethods();
    }
    return this;
  },
  addMethods: function() {
    var object = this.config.object;
    object.toggleButtons = this.toggleButtons;
  },
  toggleButtons: function(element, data) {
    //console.log(data);
    var _this = cmpButtonsToggle;
    if (this.groupName) {
      var $group = this.$getGroup(this.groupName),
        $element = [];
      if ($group.length) {
        if (element) {
          $element = $(element);
          if (data && data.multiGroupToggle !== undefined) {
            var $parent = $element.parent();
            if ($parent.length) {
              $group = this.$getGroup(this.groupName, $parent);
            }
          }
        } else {
          $element = $group.filter('.' + _this.config.activeClassName).first();
        }
        if ($element.length) {
          var index,
            $next = [];
          if ('index' in this.options) {
            index = $element.data('index');
            if (index == undefined) {
              index = $group.index($element);
            }
            if (index != undefined) {
              $next = $group
                .filter('[data-index="' + (index + 1) + '"]')
                .first();
              if (!$next.length) {
                if (index == $group.length - 1) {
                  $next = $group.first();
                } else {
                  $next = $element.next(
                    '[data-button="' + this.groupName + '"]'
                  );
                }
              }
            }
          } else {
            $next = $element.next('[data-button="' + this.groupName + '"]');
          }

          if (!$next.length) {
            $next = $group.first();
          }
          if ($next.length) {
            $element.removeClass(_this.config.activeClassName);
            $group.addClass(_this.config.disabledClassName);
            $next
              .removeClass(_this.config.disabledClassName)
              .addClass(_this.config.activeClassName);
          }
        }
      }
    }
  },
};
