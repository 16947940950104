/*####### cmp-tray-alert.js - v1.1 - 27.09.2017 #######*/

export const cmpTrayAlert = {
    cls: 'cmpTrayAlert',
    $tray: [],
    config: {
        defaultType: 'message',
        defaultDelay: 10,
        fade: 0,
        insert: 0,
        selector: 'body'
    },
    init: function(config){

        if(config && config.constructor === Object){
            for(var i in config){
                this.config[i] = config[i];
            }
        }
        this.$tray = $('.' + this.cls);
        if(!this.$tray.length){
            this.$tray = $('<div>').addClass(this.cls).appendTo(this.config.selector);
        }
        this.listener();
        
    },
    listener: function(){
        var _this = this;
        $(this.config.selector).on('click', '[data-tray-alert]', function(event){
            var $this = $(this),
                data = $this.data();
            _this.show.call(_this, data.trayAlert, data.trayAlertType, data.trayAlertDelay, data.trayAlertCallback, data.trayAlertId, $this);
        })
        .on('click', '[data-tray-alert-hide]', function(event){
            _this.remove.call(_this, $(this).parents('.item'));
        });
    },
    show: function(message, type, delay, callback, id, $target){
        if (this.$tray.length === 0){
            this.$tray = $('.' + this.cls);
            if(!this.$tray.length){
                this.$tray = $('<div>').addClass(this.cls).appendTo(this.config.selector);
            }
        }
        var _this = this,
        type = type || this.config.defaultType,
        delay = delay || (delay === false ? false : this.config.defaultDelay),
        t = id || message + type + delay + '';
        
        var hash = 0,
            char;
        if (t.length == 0) return hash;
        for (var i = 0; i < t.length; i++) {
            char = t.charCodeAt(i);
            hash = ((hash<<5)-hash)+char;
            hash = hash & hash;
        }

        var $close = $('<div class="close icon-cross" data-key-group="cmp-tray-alert" data-tray-alert-hide></div>');
        
        var $item = $('<div>')
            .addClass(type)
            .attr('data-id', hash)
            .html('<div class="text">' + message + '</div>')
            .fadeIn(this.config.fade, function(){
                var $this = $(this).addClass('item');
                setTimeout(function(){
                    $this.addClass('visible');
                    if(callback && typeof callback == 'function'){
                        callback.call(_this, $this, $target);
                    }else if(callback && typeof callback == 'string'){
                        try{
                            eval(callback + '.call(_this, $this, $target)');
                        }catch(e){
                            
                        }
                    }
                },100);
            });
            if(delay !== false){
                $item.delay(delay * 1000)
                .show(0, function(){
                    $(this).addClass('deleted');
                })
                .delay(1000)
                .fadeOut(this.config.fade, function(){
                    _this.remove.call(_this, $(this));
                });
            }
        $close.appendTo($item);

        var $citem = this.$tray.find('[data-id="' + hash + '"]');

        if($citem.length){
            $citem.hide().after($item);
            $citem.remove();
        }else{
            if(this.config.insert){
                $item.prependTo(this.$tray);
            }else{
                $item.appendTo(this.$tray);
            }
        }
        return $item;
    },
    remove: function($el){
        if($el.length){
            $el.remove();
        }
    }
};
