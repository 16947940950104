/*####### cmp-dialog.js - v1.1 - 27.09.2017 #######*/

export const cmpDialog = {
    cls: 'cmpDialog',
    $contayner: [],
    config: {
        buttonOk: 'Ok',
        buttonCancel: 'Cancel',
        buttonClose: 'Close',
        keyReturn: 27,
        selector: 'body'
    },
    init: function(config){

        if(config && config.constructor === Object){
            for(var i in config){
                this.config[i] = config[i];
            }
        }
        this.$contayner = $('.' + this.cls);
        if(!this.$contayner.length){
            this.$contayner = $('<div></div>').addClass(this.cls).appendTo(this.config.selector);
        }
        this.listener();
    },
    listener: function(){
        var _this = this;

        $(this.config.selector).on('click', '[data-dialog]', function(event){
            var data = $(this).data();
            _this.show.call(_this, data.dialogType || 'alert', data.dialog, data.dialogTitle, data.dialogCallback, data.dialogStyle);
        })
        .on('click', '[data-dialog-ok]', function(event){
            _this.remove.call(_this, 1);
        })
        .on('click', '[data-dialog-cancel]', function(event){
            _this.remove.call(_this, $(this).data('dialog-cancel'));
        });
    },
    $selectedElement: [],
    alert: function(message, title, callback, style){
        this.show.call(this, 'alert', message, title, callback, style);
    },
    confirm: function(message, title, callback, style){
        this.show.call(this, 'confirm', message, title, callback, style);
    },
    content: function(message, title, callback, style, afterVisible){
        this.show.call(this, 'content', message, title, callback, style, afterVisible);
    },
    active: false,
    show: function(type, content, title, callback, style, afterVisible){
        var _this = this;
        if(this.active){
            this.empty();
            this.keydownOff();
        }
        if('keyNavigation' in window){
            this.$selectedElement = keyNavigation.$getSelectedElement();
        }
        this.$contayner.addClass(type);
        if(style){
            this.$contayner.addClass(style);
        }
        var $overlay = $('<div data-dialog-cancel="0"></div>').addClass('overlay').appendTo(this.$contayner);
        var $box = $('<div></div>').addClass('box').appendTo(this.$contayner); 

        $('<div class="content">' + content + '</div>').appendTo($box);
        if(title){
            $('<div class="title">' + title + '</div>').prependTo($box);
            $box.addClass('head');
        }
        var $close = $('<span class="close icon-cross" data-key-group="cmp-dialog-hide" data-down="cmp-dialog" data-up data-right data-left data-dialog-cancel="0" title="' + this.config.buttonClose + '"></span>').prependTo($box);

        $(document).on('keydown.escapeDialog', function(event){
            if(event.keyCode == _this.config.keyReturn){
                event.stopPropagation();
                $close.trigger('click');
            }
        });        
        
        if(type == 'alert' || type == 'confirm'){
            var $butons = $('<div>').addClass('buttons').appendTo($box);
            var $ok = $('<button class="ok" data-key-group="cmp-dialog-button" data-left data-down data-up="cmp-dialog-hide" data-dialog-ok title="' + this.config.buttonOk + '"></button>').appendTo($butons);
            if(type == 'alert'){
                $ok.attr('data-right', '');
                $close.attr('data-dialog-cancel', 1);
                $overlay.attr('data-dialog-cancel', 1);
            }
            if(type == 'confirm'){
                $('<button class="cancel" data-key-group="cmp-dialog-button" data-right data-down data-up="cmp-dialog-hide" data-dialog-cancel="0" title="' + this.config.buttonCancel + '"></button>').appendTo($butons);
            }
            this.$contayner.addClass('visible');
            setTimeout(function(){
                $ok.focus();
            },10);
        }else{
            this.$contayner.addClass('visible');
            setTimeout(function(){
                $close.focus();
                if(afterVisible && typeof afterVisible == 'function'){
                    afterVisible.call(_this);
                }
            },10);
        }
        this.callback = callback;
        this.active = true;
    },
    callback: false,
    empty: function(){
        this.$contayner.empty().attr('class','').addClass(this.cls);
    },
    keydownOff: function(){
        $(document).off('keydown.escapeDialog');
    },
    remove: function(cancel){
        this.empty();
        this.keydownOff();
        if(this.callback && typeof this.callback == 'function'){
            var r = this.callback.call(this, cancel);
            if(!r){
                this.selectKeyElement();
            }
        }else if(this.callback && typeof this.callback == 'string'){
            try{
                var r = eval(this.callback + '.call(this, cancel)');
                if(!r){
                    this.selectKeyElement();
                }
            }catch(e){
                
            }
        }else{
            this.selectKeyElement();
        }
        this.callback = false;
        this.active = false;
    },
    selectKeyElement: function(){
        if('keyNavigation' in window && this.$selectedElement.length){
            this.$selectedElement.keySelectElement();
        }
    }
};
